import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import {Alert} from "@material-ui/lab";
import '../App.css';
import { Snackbar } from "@material-ui/core";
import logo from '../assets/logo.svg';

export default function Login(){
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = useState({
        message: "",
        error: false,
    });

    useEffect(() => {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            let errorValue = params.get('error');
            if(errorValue){
                updateStatus("", true);
            }else {
                updateStatus("", false);
            }
        }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const updateStatus = (newMessage: string, newError: boolean) => {
        setStatus({
            message: newMessage,
            error: newError,
        });
    }

    const useStyles = makeStyles((theme) => ({
        container: {
            padding: theme.spacing(3),
        },
    }));

    const classes = useStyles();
    const { handleSubmit, register } = useForm<FormData>();

    interface FormData {
        username: string;
        password: string;
    }

    return <>
        <div className="c-header">
            <Container className={classes.container} maxWidth="xs">
                <div className="c-header__logo">
                    <img className="c-header__icon" src={logo} alt="logo" />

                    <span className="c-header__logo-title">
                        EU FarmBook
                    </span>
                </div>
            </Container>
        </div>

        <Container className={classes.container} maxWidth="xs">
            <Snackbar open={status.error} autoHideDuration={8000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Unable to authenticate. If you do not yet have or can't remember your credentials,
                    please contact contact Tim Hendriks through e-mail or slack.
                    </Alert>
            </Snackbar>
            <form action={'/auth/perform_login'} method="post">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className="c-header__description">
                                    In order to upload objects through the form, a separate privileged account is currently required.
                                    Please contact us at <a href = "mailto: eufarmbook@ugent.be">eufarmbook@ugent.be</a> for more information.
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <label className="c-input">
                                    <input
                                        type="text"
                                        className={`c-input__field has-label`}
                                        placeholder=" "
                                        {...register('username')}
                                    />

                                    <span className="c-input__label">
                                        Username
                                        </span>
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                <label className="c-input">
                                    <input
                                        type="password"
                                        className={`c-input__field has-label`}
                                        placeholder=" "
                                        {...register('password')}
                                    />

                                    <span className="c-input__label">
                                        Password
                                        </span>
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="secondary" fullWidth type="submit" variant="contained">
                            Log in
                            </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    </>;
}