import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from "./pages/Login";
import UploadForm from "./pages/UploadForm";
import GoogleFontLoader from 'react-google-font-loader';

function App() {
    return <>
        <GoogleFontLoader
            fonts={[
                {
                font: 'Roboto',
                weights: [400, 500, 700],
                },
                {
                font: 'Poppins',
                weights: [400, 500, 700],
                },
            ]}
        />

        <Router>
            <Switch>
                <Route exact path="/" component={UploadForm} />
                <Route path="/auth/login" component={Login} />
            </Switch>
        </Router>
    </>
}

export default App;
