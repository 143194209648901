import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useCallback, useEffect} from 'react';
import {IChangeEvent, ISubmitEvent, ErrorSchema} from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import '../App.css';
import Form from "@rjsf/material-ui";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {LivestockValues, CropFarmingValues, EconomicsValues, EnvironmentValues, ForestryValues, SocietyValues} from "../components/SecondSubjectEnums";
import { CSVDownload, CSVLink } from 'react-csv';

export default function UploadForm(){
    const [scrollTop, setScrollTop] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState<any>({});
    const [csvArr, setCsvArr] = React.useState<any>([]);
    useEffect(() => { setFormData(formData) }, [])
    const onScroll = useCallback((e) => {
        const currentScroll = e.currentTarget.scrollTop;
        setScrollTop(currentScroll);
    }, []);


    const handleClose = () => {
        setOpen(false);
    };

    const clearForm = () => {
        setFormData({});
    }

    const handleCsvArr = (data: any) => {
        setCsvArr(data);
    };

    // const checkSecondLevelSubjects = (e: IChangeEvent<any>, es?: ErrorSchema) => {
    //     let formData: any = e.formData; //Scoped variable as the state variable is not updated immediately.
    //     console.log(JSON.stringify(formData));
    //     if(formData.hasOwnProperty("SubjectOne")){
    //         if(formData.SubjectOne.includes('crop farming')){
    //             //@ts-ignore
    //             if(schema.definitions && schema.definitions['SubjectTwo'] && schema.definitions['SubjectTwo'].items.enum){
    //                 //@ts-ignore
    //                 var items: any[] = []
    //                 if(formData.SubjectOne.includes('crop farming')){
    //                     console.log("yup");
    //                     items.concat(CropFarmingValues);
    //                 }
    //                 if(formData.SubjectOne.includes('livestock')){
    //                     items.concat(LivestockValues);
    //                 }
    //                 if(formData.SubjectOne.includes('economics')){
    //                     items.concat(EconomicsValues);
    //                 }
    //                 if(formData.SubjectOne.includes('environment')){
    //                     items.concat(EnvironmentValues);
    //                 }
    //                 if(formData.SubjectOne.includes('society')){
    //                     items.concat(SocietyValues);
    //                 }
    //                 if(formData.SubjectOne.includes('forestry')){
    //                     items.concat(ForestryValues);
    //                 }
    //                 let set = new Set(items);
    //                 let iterator = set.values();
    //                 //@ts-ignore
    //                 schema.definitions['SubjectTwo'].items.enum = Array.from(iterator);
    //             }
    //         }
    //     }
    // }

    const submitForm = (e: ISubmitEvent<any>) => {
        
        let formData: any = e.formData; //Scoped variable as the state variable is not updated immediately.
        setFormData(e.formData);
        const formDataWrapper = new FormData();
        const fileForm = new FormData();
        const bearerToken = '';
        //console.log(JSON.stringify(formData));
        let file: any = formData.KnowledgeObjectLocation.File
        delete formData.KnowledgeObjectLocation.File;
        if(formData.KnowledgeObjectLocation["Is your knowledge object available online?"]
            === "No, I will upload the object from my local drive") {
            var byteString = atob(file.split(',')[1]);
            var mimeString = file.split(',')[0].split(':')[1].split(';')[0]
            var nameString = file.split(',')[0].split(';')[1].split('=')[1]
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ab], {type: mimeString});
            formDataWrapper.append('file', blob, nameString);
        }

        const categoryObj = formData['Category'];
        const categoryName = categoryObj['The category of the object:'];
        delete categoryObj['The category of the object:'];
        const categoryAvail: any = (Object.values(categoryObj))[0];
        const types = categoryAvail.join(';');
        
        const headers = [
           "Id",
           "dc.title",
            "creativework.description",
            "dcterms.creator",
            "creativework.keywords",
            "creativework.inLanguage",
            "creativework.contentLocation",
            "dcterms.format",
            "creativework.dateCreated",
            "knowledgeobject.dateExtracted",
            "creativework.potentialAction",
            "creativework.url",
            "knowledgeobject.filetype",
            "dcterms.subject",
            "dcterms.type",
            "knowledgeobject.macroCategory",
            "creativework.license",
            "knowledgeobject.project.acronym",
            "knowledgeobject.project.name",
            "knowledgeobject.project.url",
        ];

        const data = [            
                "1", 
                formData['Title'], 
                formData['Description'], 
                formData['Creators'] ? formData['Creators'] : "n.a.",  // array
                formData['Keywords'] ? formData['Keywords'] : "n.a.", // array 
                formData['Languages'] ? formData['Languages'] : "n.a.", // array
                formData['GeographicalLocations'] ? formData['GeographicalLocations'] : "n.a.", // array
                 "n.a.", 
                formData['KnowledgeObjectDate'].ExactDate.date ? formData['KnowledgeObjectDate'].ExactDate.date : "n.a." , // needs checks
                "n.a.", 
                formData['IntendedPurpose']['What is the intended purpose of the object?'] ? formData['IntendedPurpose']['What is the intended purpose of the object?'] : "n.a.", 
                formData['KnowledgeObjectLocation']['Knowledge Object URL'] ? formData['KnowledgeObjectLocation']['Knowledge Object URL'] : "n.a.", // needs checks
                categoryName, // needs extra brain
                formData['SubjectOne'] ? formData['SubjectOne'] : "n.a.", // array
                types, 
                formData['SubjectTwo:'] ? formData['SubjectTwo:'] : "n.a.", // array
                formData['License']['Under which license will the knowledge object be made available?'], 
                formData['ProjectAcronym'], 
                formData['ProjectName'], 
                formData['ProjectURL']               
        ];



        const csvArr = [[...headers], []];

        //console.log("data: ", data);

        let csv = headers.join(';') + '\n';
        let row = "";
        for (let i = 0; i <= data.length - 1; i += 1) {
            if (Array.isArray(data[i])) {
                let str = "";
                for (let j = 0; j <= data[i].length - 1; j +=1) {
                    str += (j === data[i].length - 1) ? data[i][j] : (data[i][j] + ';');
                }
                csvArr[1].push(str);
                row += '\"'+ str + '\";';
            } else {
                csvArr[1].push(data[i]);
                row += (i === data.length - 1) ? data[i] : (data[i] + ';');
            }
        }
        csv += (row+'\n');

        let csvContent = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        fileForm.append('file', csvContent);

        fetch('https://upload-prd.eufarmbook.eu/api/register', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic YWRtaW5AZXVmYXJtYm9vay5ldTpaKlJ1TkVtd3N4eWJeMw==',
                'Cookie': 'JSESSIONID=A4DF302A506EE8B431F2A44F7C8817BB',
            },
            body: fileForm
        })
            .then(response => {
                
                console.log("Response: " + JSON.stringify(response));
                if (response.ok) {
                        window.scrollTo(0, 0)
                        setOpen(true);
                        setCsvArr(csvArr);
                } else {
                    console.log("response not ok: " + JSON.stringify(response));
                }
            })
            .catch(error => {
                console.log("error: " + JSON.stringify(error));
            });
    }

    const transformErrors = (errors: any) => {
        return errors.map((error: any) => {
            if (error.name === "enum") {
                error.message = ""
            }
            if (error.name === "required") {
                error.message = "Please provide a value for this field"
            }
            if (error.name === "format" || error.name === "pattern") {
                error.message = "Please provide the correct format for this field"
            }
            if (error.name === "pattern") {
                error.message = "Please make sure the url starts with http:// or https://"
            }
            if (error.name === "oneOf") {
                error.message = ""
            }
            return error;
        });
    }

    const validate = (formData: any, errors: any) => {
        if(formData.Title && formData.Title.length > 200) {
            errors.Title.addError("Please provide a shorter title");
        }
        if (formData.ProjectName && formData.ProjectName.length > 100) {
            errors.ProjectName.addError("Please provide a shorter project name");
        }
        if (formData.ProjectAcronym && formData.ProjectAcronym.length > 20) {
            errors.ProjectAcronym.addError("Please provide a shorter project acronym");
        }
        if (formData.ProjectURL && formData.ProjectURL.length > 2000) {
            errors.ProjectURL.addError("Please provide a shorter project URL");
        }
        if(formData.Description && formData.Description.length > 500) {
            errors.Description.addError("Please provide a shorter description");
        }
        return errors;
    }

    var secondSubjectEnum: any[] = [];

    var schema: JSONSchema7 = {
        "type": "object",
        "required": ["KnowledgeObjectLocation",
            "Title",
            "Description",
            "ProjectName",
            "ProjectAcronym",
            "ProjectURL",
            //"Category",
            //"Keywords",
            //"Creators",
            //"Languages",
            //"License",
            //"IntendedPurpose",
            //"KnowledgeObjectDate",
            //"GeographicalLocations"
            ]
        ,
        "definitions": {
            "license": {
                "description": "More information about Creative Commons (CC) licenses can be found at https://creativecommons.org/about/cclicenses/, including the \"Creative Commons License Chooser\" tool.",
                "type": "object",
                "properties": {
                    "Under which license will the knowledge object be made available?": {
                        "type": "string",
                        "default": "CC BY",
                        "enum": ["CC BY",
                            "CC BY-SA",
                            "CC BY-NC",
                            "CC BY-NC-SA",
                            "CC BY-ND",
                            "CC BY-NC-ND",
                            "Other"]
                    }
                },
                // "required": [
                //     "Under which license will the knowledge object be made available?"
                // ],
                "dependencies": {
                    "Under which license will the knowledge object be made available?": {
                        "oneOf": [
                            {
                                "properties": {
                                    "Under which license will the knowledge object be made available?": {
                                        "enum": [
                                            "Other"
                                        ]
                                    },
                                    "License URL": {
                                        "type": "string",
                                        "format": "uri",
                                        "pattern": "^http.?://",
                                        "title": "Please provide the URL to the license"
                                    }
                                },
                                // "required": [
                                //     "License URL"
                                // ]
                            },
                            {
                                "properties": {
                                    "Under which license will the knowledge object be made available?": {
                                        "enum": [
                                            "CC BY",
                                            "CC BY-SA",
                                            "CC BY-NC",
                                            "CC BY-NC-SA",
                                            "CC BY-ND",
                                            "CC BY-NC-ND",]
                                    }
                                }
                            }
                        ]
                    }
                }
            },
            "completiondate": {
                "type": "object",
                "properties": {
                    "Is the date of completion for the object available?": {
                        "type": "string",
                        "enum": ["I only know the year of completion",
                            "I have the exact date available"
                        ]
                        ,
                        "default": "I have the exact date available"
                    }
                },
                // "required": [
                //     "Is the date of completion for the object available?"
                // ],
                "dependencies": {
                    "Is the date of completion for the object available?": {
                        "oneOf": [
                            {
                                "properties": {
                                    "Is the date of completion for the object available?": {
                                        "enum": [
                                            "I have the exact date available"
                                        ]
                                    },
                                    "ExactDate": {
                                        "title": "Date of Completion",
                                        "description": "The date on which work on the knowledge object was completed",
                                        "type": "object",
                                        "properties": {
                                            "date": {
                                                "type": "string",
                                                "format": "date"
                                            }
                                        },
                                        // "required": [
                                        //     "date"
                                        // ],
                                    }
                                },
                                // "required": [
                                //     "ExactDate"
                                // ]
                            },
                            {
                                "properties": {
                                    "Is the date of completion for the object available?": {
                                        "enum": [
                                            "I only know the year of completion"
                                        ]
                                    },
                                    "yearOfCompletion": {
                                        "title": "Year of Completion",
                                        "type": "integer",
                                        "minimum": 1900,
                                        "maximum": 2100
                                    }
                                },
                                // "required": [
                                //     "yearOfCompletion"
                                // ]
                            }
                        ]
                    }
                }
            },
            "SubjectOne": {
                "title": "High Level Topics",
                "description": "Please select the most appropriate high level topics.",
                "type": "array",
                "items":
                    {
                        "title": "subject1",
                        "type": "string",
                        "default": "crop farming",
                        "enum": [
                            "forestry",
                            "livestock",
                            "crop farming",
                            "economics",
                            "environment",
                            "society"
                        ]
                    }
            },
            "SubjectTwo": {
                "title": "Sub-level Subtopics",
                "description": "Please select the most appropriate sub-level subtopics.",
                "type": "array",
                "items":
                    {
                        "title": "subject2",
                        "type": "string",
                        "default": "waste, by-products and residues management",
                        "enum": [
                            "Farming equipment and machinery",
                            "Animal husbandry and welfare",
                            "Plant production and horticulture",
                            "Landscape/land management",
                            "Pest/disease control",
                            "Fertilisation and nutrient management",
                            "Soil management/functionality",
                            "Genetic resource",
                            "Forestry",
                            "Energy management",
                            "Supply chain, marketing and consumption",
                            "Farming/forestry competitiveness and diversification",
                            "Food quality, processing and nutrition",
                            "Biodiversity and nature management",
                            "Waste, by-products and residues management",
                            "Climate and climate change",
                            "Biomass production",
                            "Aquaculture",
                            "Agroecology",
                            "Organic farming",
                            "Digitalization",
                            "Farm deiversification/new business models",
                            "Competitiveness",
                            "Crop rotation/crop diversification",
                            "AKIS",
                            "Water management",
                        ]
                        //secondSubjectEnum
                    }
            },
            "category": {
                "type": "object",
                "title": "Category & Type",
                "description": "The category that best fits the type of the supplied knowledge object",
                "properties": {
                    "The category of the object:": {
                        "type": "string",
                        "enum": ["Document",
                            "Slideshow/Presentation",
                            "Video",
                            "Podcast",
                            "Image",
                            "Software Application",
                            "Dataset"
                        ]
                    }
                },
                // "required": [
                //     "The category of the object:"
                // ],
                "dependencies": {
                    "The category of the object:": {
                        "oneOf": [
                            {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Document"
                                        ]
                                    },
                                    "Document Type": {
                                        "title": "Document Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["Article in conference proceedings",
                                                    "Book",
                                                    "Booklet",
                                                    "Brochure",
                                                    "Chapter in edited volume",
                                                    "Deliverable report",
                                                    "Factsheet",
                                                    "Flyer",
                                                    "Handbook",
                                                    "Guide",
                                                    "Journal article",
                                                    "Manual",
                                                    "Milestone report",
                                                    "Newsletter",
                                                    "Policy brief",
                                                    "Practice abstract",
                                                    "Press release",
                                                    "Review document",
                                                    "Report/paper",
                                                    "Technical/technology article",
                                                    "Technical information/specifications card",
                                                    "Tutorial"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Document Type"
                                // ]
                            },
                            {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Slideshow/Presentation"
                                        ]
                                    },
                                    "Presentation Type": {
                                        "title": "Presentation Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["Educational/training presentation",
                                                    "Guide",
                                                    "Informative presentation",
                                                    "Tutorial (similar to guide)",
                                                    "Motivational presentation",
                                                    "Decision-making presentation",
                                                    "Problem-solving presentation"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Presentation Type"
                                // ]
                            },
                            {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Video"
                                        ]
                                    },
                                    "Video Type": {
                                        "title": "Video Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["Case study",
                                                    "Documentary video",
                                                    "Educational/training video",
                                                    "Event capturing video",
                                                    "Guide",
                                                    "Interview video",
                                                    "Presentation/live talk capturing video",
                                                    "Product/feature review video",
                                                    "Question-and-answer video",
                                                    "Simulation video",
                                                    "Testimonial",
                                                    "Tutorial/how-to video",
                                                    "Vlog",
                                                    "Webinar"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Video Type"
                                // ]
                            }
                            , {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Podcast"
                                        ]
                                    },
                                    "Podcast Type": {
                                        "title": "Podcast Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["Educational/training podcast",
                                                    "Event capturing podcast",
                                                    "Interview",
                                                    "Solo podcast",
                                                    "Tutorial/guide",
                                                    "Commentary",
                                                    "Panel discussion",
                                                    "Question-and-answer podcast",
                                                    "On-demand seminar",
                                                    "Audio magazine"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Podcast Type"
                                // ]
                            }
                            ,
                            {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Image"
                                        ]
                                    },
                                    "Image Type": {
                                        "title": "Image Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["Chart/graph",
                                                    "Infographic",
                                                    "Interactive figure/image",
                                                    "Interactive map",
                                                    "Static figure/image",
                                                    "Static map"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Image Type"
                                // ]
                            }
                            , {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Software Application"
                                        ]
                                    },
                                    "Application Type": {
                                        "title": "Application Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["AI software",
                                                    "Business software",
                                                    "Data repository/database",
                                                    "Data analysis software",
                                                    "Decision support tool",
                                                    "Educational/training software",
                                                    "FMIS",
                                                    "Game",
                                                    "Scientific software",
                                                    "Simulation"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Application Type"
                                // ]
                            },
                            {
                                "properties": {
                                    "The category of the object:": {
                                        "enum": [
                                            "Dataset"
                                        ]
                                    },
                                    "Dataset Type": {
                                        "title": "Dataset Type",
                                        "type": "array",
                                        "items":
                                            {
                                                "type": "string",
                                                "enum": ["Auditory Data",
                                                    "Crop-related Data",
                                                    "Geospatial Data",
                                                    "Graph-related Data",
                                                    "Imagery Data",
                                                    "Input-related Data",
                                                    "Network-related Data",
                                                    "Temporal Data",
                                                    "Textual Data",
                                                    "Video Data",
                                                    "Weather/climate Data",
                                                    "Yield-related Data"]
                                            }
                                    }
                                },
                                // "required": [
                                //     "Dataset Type"
                                // ]
                            }
                        ]
                    }
                }
            },
            "location": {
                "title": "Location",
                "type": "object",
                "properties": {
                    "Is your knowledge object available online?": {
                        "type": "string",
                        "enum": [
                            "Yes, I will provide the URL to the object",
                            //"No, I will upload the object from my local drive",
                        ],
                        "default": "Yes, I will provide the URL to the object"
                    }
                },
                // "required": [
                //     "Is your knowledge object available online?"
                // ],
                "dependencies": {
                    "Is your knowledge object available online?": {
                        "oneOf": [
                            {
                                "properties": {
                                    "Is your knowledge object available online?": {
                                        "enum": [
                                            "No, I will upload the object from my local drive"
                                        ]
                                    },
                                    "File": {
                                        "type": "string",
                                        "format": "data-url",
                                        "title": "the file to upload as a knowledge object"
                                    }
                                },
                                // "required": [
                                //     "File"
                                // ],
                            },
                            {
                                "properties": {
                                    "Is your knowledge object available online?": {
                                        "enum": [
                                            "Yes, I will provide the URL to the object"
                                        ]
                                    },
                                    "Knowledge Object URL": {
                                        "type": "string",
                                        "format": "uri",
                                        "pattern": "^http.?://",
                                        "description": "the URL to the knowledge object"
                                    }
                                },
                                // "required": [
                                //     "Knowledge Object URL"
                                // ]
                            }
                        ]
                    }
                }
            }
        },
        "properties": {
            "KnowledgeObjectLocation": {
                "title": "Knowledge Object",
                "$ref": "#/definitions/location"
            },
            "Title": {
                "type": "string",
                "title": "Title",
                "description": "The title of the knowledge object",
            },
            "Description": {
                "type": "string",
                "title": "Description",
                "description": "A short (500 character or around 100 words) summary of the knowledge object."
            },
            "ProjectName": {
                "type": "string",
                "title": "Project Name",
                "description": "The project under which the knowledge object was created"
            },
            "ProjectAcronym": {
                "type": "string",
                "title": "Project Acronym",
                "description": "The acronym of the project under which the knowledge object was created"
            },
            "ProjectURL": {
                "type": "string",
                "title": "Project URL",
                "description": "The main URL of the project under which the knowledge object was created"
            },
            "SubjectOne": {
                "title": "High Level Subjects",
                "$ref": "#/definitions/SubjectOne"
            },
            "SubjectTwo": {
                "title": "Specific Subjects",
                "$ref": "#/definitions/SubjectTwo"
            },
            "Category": {
                "title": "Category & Type",
                "$ref": "#/definitions/category"
            },
            "Keywords": {
                "title": "Keywords",
                "description": "Please provide a list of keywords indicative of the content of the object",
                "type": "array",
                "items": {
                    "type": "string"
                }
            },
            "Creators": {
                "title": "Creators",
                "description": "Provide the name(s) of the creator(s) of the object",
                "type": "array",
                "items": {
                    "type": "string"
                }
            },
            "Languages": {
                "title": "Languages",
                "description": "Please provide the language(s) in which the knowledge object is available. In case translations of the object are seperate files or pages, please handle each translation as it's own object.",
                "type": "array",
                "items":
                    {
                        "title": "Language",
                        "type": "string",
                        "default": "English",
                        "enum": ["Bulgarian",
                            "Croatian",
                            "Czech",
                            "Danish",
                            "Dutch",
                            "English",
                            "Estonian",
                            "Finnish",
                            "French",
                            "German",
                            "Greek",
                            "Hungarian",
                            "Irish",
                            "Italian",
                            "Latvian",
                            "Lithuanian",
                            "Maltese",
                            "Polish",
                            "Portuguese",
                            "Romanian",
                            "Slovak",
                            "Slovene",
                            "Spanish",
                            "Swedish"]
                    }
            },
            "License": {
                "title": "License",
                "$ref": "#/definitions/license"
            },
            "IntendedPurpose": {
                "title": "Intended Purpose",
                "type": "object",
                "properties": {
                    "What is the intended purpose of the object?": {
                        "type": "string",
                        "enum": ["Access to Data",
                            "Communication",
                            "Data storage/Processing",
                            "Decision-making support",
                            "Dissemination",
                            "Education/Training",
                            "Evaluation",
                            "Experimentation",
                            "Modelling",
                            "Monitoring",
                            "Prediction/Forecasting"]
                    }
                }
            },
            "KnowledgeObjectDate": {
                "title": "Completion Date",
                "$ref": "#/definitions/completiondate"
            },
            "GeographicalLocations": {
                "title": "Geographical Location(s)",
                "description": "Please select all countries referenced in the knowledge object's contents.",
                "type": "array",
                "items":
                    {
                        "title": "Location",
                        "type": "string",
                        "default": "English",
                        "enum": ["Afghanistan",
                            "Albania",
                            "Algeria",
                            "Andorra",
                            "Angola",
                            "Antigua and Barbuda",
                            "Argentina",
                            "Armenia",
                            "Australia",
                            "Austria",
                            "Azerbaijan",
                            "Bahamas",
                            "Bahrain",
                            "Bangladesh",
                            "Barbados",
                            "Belarus",
                            "Belgium",
                            "Belize",
                            "Benin",
                            "Bhutan",
                            "Bolivia",
                            "Bosnia and Herzegovina",
                            "Botswana",
                            "Brazil",
                            "Brunei",
                            "Bulgaria",
                            "Burkina Faso",
                            "Burundi",
                            "Côte d'Ivoire",
                            "Cabo Verde",
                            "Cambodia",
                            "Cameroon",
                            "Canada",
                            "Central African Republic",
                            "Chad",
                            "Chile",
                            "China",
                            "Colombia",
                            "Comoros",
                            "Congo",
                            "Costa Rica",
                            "Croatia",
                            "Cuba",
                            "Cyprus",
                            "Czechia",
                            "Democratic Republic of the Congo",
                            "Denmark",
                            "Djibouti",
                            "Dominica",
                            "Dominican Republic",
                            "Ecuador",
                            "Egypt",
                            "El Salvador",
                            "Equatorial Guinea",
                            "Eritrea",
                            "Estonia",
                            "Eswatini",
                            "Ethiopia",
                            "Fiji",
                            "Finland",
                            "France",
                            "Gabon",
                            "Gambia",
                            "Georgia",
                            "Germany",
                            "Ghana",
                            "Greece",
                            "Grenada",
                            "Guatemala",
                            "Guinea",
                            "Guinea-Bissau",
                            "Guyana",
                            "Haiti",
                            "Holy See",
                            "Honduras",
                            "Hungary",
                            "Iceland",
                            "India",
                            "Indonesia",
                            "Iran",
                            "Iraq",
                            "Ireland",
                            "Israel",
                            "Italy",
                            "Jamaica",
                            "Japan",
                            "Jordan",
                            "Kazakhstan",
                            "Kenya",
                            "Kiribati",
                            "Kuwait",
                            "Kyrgyzstan",
                            "Laos",
                            "Latvia",
                            "Lebanon",
                            "Lesotho",
                            "Liberia",
                            "Libya",
                            "Liechtenstein",
                            "Lithuania",
                            "Luxembourg",
                            "Madagascar",
                            "Malawi",
                            "Malaysia",
                            "Maldives",
                            "Mali",
                            "Malta",
                            "Marshall Islands",
                            "Mauritania",
                            "Mauritius",
                            "Mexico",
                            "Micronesia",
                            "Moldova",
                            "Monaco",
                            "Mongolia",
                            "Montenegro",
                            "Morocco",
                            "Mozambique",
                            "Myanmar (formerly Burma",
                            "Namibia",
                            "Nauru",
                            "Nepal",
                            "Netherlands",
                            "New Zealand",
                            "Nicaragua",
                            "Niger",
                            "Nigeria",
                            "North Korea",
                            "North Macedonia",
                            "Norway",
                            "Oman",
                            "Pakistan",
                            "Palau",
                            "Palestine State",
                            "Panama",
                            "Papua New Guinea",
                            "Paraguay",
                            "Peru",
                            "Poland",
                            "Portugal",
                            "Qatar",
                            "Romania",
                            "Russia",
                            "Rwanda",
                            "Saint Kitts and Nevis",
                            "Saint Lucia",
                            "Saint Vincent and the Grenadines",
                            "Samoa",
                            "San Marino",
                            "Sao Tome and Principe",
                            "Saudi Arabia",
                            "Senegal",
                            "Serbia",
                            "Seychelles",
                            "Sierra Leone",
                            "Singapore",
                            "Slovakia",
                            "Slovenia",
                            "Solomon Islands",
                            "Somalia",
                            "South Africa",
                            "South Korea",
                            "South Sudan",
                            "Spain",
                            "Sri Lanka",
                            "Sudan",
                            "Suriname",
                            "Sweden",
                            "Switzerland",
                            "Syria",
                            "Tajikistan",
                            "Tanzania",
                            "Thailand",
                            "Timor-Leste",
                            "Togo",
                            "Tonga",
                            "Trinidad and Tobago",
                            "Tunisia",
                            "Turkey",
                            "Turkmenistan",
                            "Tuvalu",
                            "Uganda",
                            "Ukraine",
                            "United Arab Emirates",
                            "United Kingdom",
                            "United States of America",
                            "Uruguay",
                            "Uzbekistan",
                            "Vanuatu",
                            "Venezuela",
                            "Vietnam",
                            "Yemen",
                            "Zambia",
                            "Zimbabwe"]
                    }
            }
        }
    };

    const CustomInput = (props: any) => {
        const { label, value, required, disabled, readonly, rawErrors, onChange, onBlur, onFocus } = props;

        return (
            <label className="c-input">
                <input
                    type="text"
                    className={`c-input__field ${rawErrors && rawErrors.length > 0 ? 'has-error' : ''} ${label ? 'has-label' : ''}`}
                    value={value}
                    placeholder=" "
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    onChange={(event) => onChange(event.target.value)}
                    onBlur={(event) => onBlur(event.target.value)}
                    onFocus={(event) => onFocus(event.target.value)}
                />

                {label &&
                    <span className="c-input__label">
                        {label}

                        {required &&
                            <span>*</span>
                        }
                    </span>
                }
            </label>
        );
    };

    const CustomUrl = (props: any) => {
        const { label, value, required, disabled, readonly, rawErrors, onChange, onBlur, onFocus } = props;

        return (
            <label className="c-input">
                <input
                    type="url"
                    className={`c-input__field ${rawErrors && rawErrors.length > 0 ? 'has-error' : ''} ${label ? 'has-label' : ''}`}
                    value={value}
                    placeholder=" "
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    onChange={(event) => onChange(event.target.value)}
                    onBlur={(event) => onBlur(event.target.value)}
                    onFocus={(event) => onFocus(event.target.value)}
                />

                {label &&
                    <span className="c-input__label">
                        {label}

                        {required &&
                            <span>*</span>
                        }
                    </span>
                }
            </label>
        );
    };

    const CustomDate = (props: any) => {
        const { value, required, disabled, readonly, rawErrors, onChange, onBlur, onFocus } = props;

        return (
            <label className="c-date">
                <input
                    type="date"
                    className={`c-date__field ${rawErrors && rawErrors.length > 0 ? 'has-error' : ''}`}
                    value={value}
                    readOnly={readonly}
                    disabled={disabled}
                    required={required}
                    onChange={(event) => onChange(event.target.value)}
                    onBlur={(event) => onBlur(event.target.value)}
                    onFocus={(event) => onFocus(event.target.value)}
                />
            </label>
        );
    };

    const CustomSelect = (props: any) => {
        const { value, options, required, disabled, rawErrors, onChange, onBlur, onFocus } = props;
        const { enumOptions: optionsData } = options;

        return (
            <label className="c-select">
                <select
                    className={`c-select__field ${rawErrors && rawErrors.length > 0 ? 'has-error' : ''}`}
                    disabled={disabled}
                    required={required}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    onBlur={(event) => onBlur(event.target.value)}
                    onFocus={(event) => onFocus(event.target.value)}
                >
                    <option disabled selected>-- select an option --</option>

                    {optionsData.map((option: any, index: any) => {
                        return <option
                            key={`option-${index}`}
                            className="c-dropdown__select-option"
                            value={option.value}
                            selected={option.value === value}
                        >
                            {option.label}
                        </option>
                    })}
                </select>
            </label>
        );
    };

    const customWidgets = {
        TextWidget: CustomInput,
        URLWidget: CustomUrl,
        DateWidget: CustomDate,
        SelectWidget: CustomSelect
    };

    const uiSchema = {
        "Keywords": {
            "ui:options": {
                "orderable": false
            }
        },
        "Languages": {
            "ui:options": {
                "orderable": false
            }
        },
        "fixedArrayOfConditionals": {
            "items": {
                "Do you want to get rid of any?": {
                    "ui:widget": "radio"
                }
            }
        },
        "ui:field": "customInput"
    };

    const log = (type: any) => console.log.bind(console, type);
    return <div onScroll={onScroll} className="App">
            <div className="c-header">
                <div className="c-header__container u-container">
                    <h1 className="c-header__title">
                        EUREKA Knowledge Object Upload Form
                    </h1>

                    <p className="c-header__description">
                        Single object upload form for the EUREKA Farmbook. For large
                        automated large scale uploads of already structured metadata,
                        please contact us on the #eureka-development slack channel.
                    </p>
                </div>
            </div>

            <div className="u-container">
                <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Upload was succesful. To speed up potential similar uploads the form is not cleared. Press 'Clear fields' to start from scratch.
                    </Alert>
                </Snackbar>

                <Form
                    schema={schema}
                    formData={formData}
                    className=""
                    uiSchema={uiSchema}
                    widgets={customWidgets}
                    onSubmit={submitForm}
                    //onChange={checkSecondLevelSubjects}
                    onError={log("errors")}
                    showErrorList={false}
                    validate={validate}
                    transformErrors={transformErrors}
                    liveValidate />

                <div className='u-container' style={{ textAlign: "center", paddingBottom: "100px", marginTop: "-100px" }}>
                    <p>Once you have filled all required fields correctly and “submit”, you will have the option to download a copy of the metadata file in CSV format. This can be used as a template for larger batch uploads of knowledge objects. Get in touch with us at eufarmbook@ugent.be if you are interested in this option!</p>
                    <CSVLink 
                        className="u-container MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" 
                        data={ csvArr } 
                        target="_blank"
                        style={{width: "200px"}}
                        separator={";"}
                        >
                        Download in CSV
                        
                    </CSVLink>
                </div>
                
            </div>
        </div>;
}